import WatchLater from '@mui/icons-material/WatchLater'
import Description from '@mui/icons-material/Description'
import Smartphone from '@mui/icons-material/Smartphone'
import Info from '@mui/icons-material/Info'
import { createElement } from 'react'

const ALLOWED_ICONS = {
  WatchLater: WatchLater,
  Description: Description,
  SmartPhone: Smartphone,
  Info: Info,
}

export type allowedIcons = keyof typeof ALLOWED_ICONS

const Icon = ({
  iconName,
  color,
}: {
  iconName: allowedIcons
  color?: string
}) => {
  const iconGuard = Object.keys(ALLOWED_ICONS)
  if (iconGuard.includes(iconName)) {
    return createElement(ALLOWED_ICONS[iconName], {
      sx: { color: color },
    })
  }
  return null
}

export default Icon
