import { useAuthentication } from '@trr/app-shell-data'
import { useGetPendingAnsokanStatusQuery } from 'api'
import { BeforeApplication, AfterApplication } from 'features'

const App = () => {
  const { sub } = useAuthentication()
  const { data } = useGetPendingAnsokanStatusQuery(
    { sub },
    { skip: sub === null }
  )
  if (data === undefined) {
    return null
  }
  const hasPendingAnsokan = Boolean(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    data.aktuellAnsokan?.ansokanUnderBearbetning
  )
  return hasPendingAnsokan ? <AfterApplication /> : <BeforeApplication />
}

export default App
