export const replaceTextVariables = (
  text: string,
  variables: { [key: string]: string | undefined }
): string => {
  const regex = (key: string): RegExp => new RegExp(`{{${key}}}`, 'g')
  Object.keys(variables).forEach((key) => {
    const varKey = variables[key]
    if (varKey !== undefined) {
      text = text.replace(regex(key), varKey)
    }
  })
  return text
}
