import { Box, Button, Divider, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { Icon, FeatureContentList } from 'components'

import { Content } from 'types'

const AfterApplication = () => {
  const content = useEpiContent<Content>()
  return (
    <Box data-testid="AfterApplication">
      <FeatureContentList listType="contentListAfterApplication" />
      <Button
        data-testid={'pendingAnsokanLink'}
        href={content.linkUrlAfterApplication}
        sx={{ mb: 6 }}
      >
        {content.linkTextAfterApplication}
      </Button>
      <Divider sx={{ mb: 3 }} />
      <Box display="flex" alignItems="flex-start" flexDirection="row" gap={2}>
        <Icon iconName={content.footnoteIcon} />
        <Typography
          dangerouslySetInnerHTML={{
            __html: content.footnoteText,
          }}
        />
      </Box>
    </Box>
  )
}

export default AfterApplication
