import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAppConfig, getIdToken } from '@trr/app-shell-data'
import { AnsokanResponse } from 'types'

const {
  COMMON: { KLIENT_API_URL },
} = getAppConfig()

const trrFetchBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: KLIENT_API_URL,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${getIdToken() ?? ''}`)
      headers.set('Content-Type', 'application/json')
      headers.set('Accept-Language', 'sv')
      return headers
    },
  })

const api = createApi({
  baseQuery: trrFetchBaseQuery(),
  endpoints: (builder) => ({
    getPendingAnsokanStatus: builder.query<
      AnsokanResponse,
      { sub: string | null }
    >({
      query: ({ sub }) => `/klient/${sub ?? ''}/ansokan`,
    }),
  }),
})

export const { useGetPendingAnsokanStatusQuery } = api

export default api
